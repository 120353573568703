import React, { useEffect, useRef, useState } from 'react';
import { Container, Title, ActionIcon, Transition, Image, Text } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
// import logo from '../../assets/images/LOGO_SHADOW.png';

// import thumbnailBigDave from '../../assets/images/Thumbnail_BigDave.png';
import thumbnailBriannaG from '../../assets/images/Brianna.png';
import thumbnailChrisM from '../../assets/images/Chris.png';
// import thumbnailJasonH from '../../assets/images/Thumbnail_JasonH.png';
import thumbnailGaryG from '../../assets/images/Thumbnail_GaryG.png';
// import thumbnailKeithA from '../../assets/images/Thumbnail_KeithA.png';
import thumbnailNephiC from '../../assets/images/Headshot_Nephi_v2.png';
import thumbnailPatrickF from '../../assets/images/Thumbnail_PatrickF.png';
import ScheduleCallModal from './ScheduleCallModal';

const videoUrl = 'https://ucarecdn.com/2b4005e4-d02f-4bd9-83ff-b9b82e036cd1/video/-/format/mp4/-/quality/normal/';


const TeamMemberCard: React.FC<{
  image: string;
  name: string;
  title: string;
  bio: string;
  bio2?: string;
  reverse?: boolean; // Make reverse optional
}> = ({ image, name, title, bio, bio2, reverse = false }) => (
  <div style={{ display: 'flex', justifyContent: 'center', flexDirection: reverse ? 'row-reverse' : 'row', gap: '40px', marginBottom: '20px', flexWrap: 'wrap', maxWidth: '85%', margin: '0 auto 100px' }}>
    <Image src={image} alt={name} width={300} height={300} style={{ borderRadius: '6%' }} />
    <div style={{ flex: 3, minWidth: 300 }}>
      <Text style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 1, color: 'black' }}>{name}</Text>
      <Text style={{ fontSize: '1.25rem', fontStyle: 'italic', fontWeight: '500', color: 'black', marginBottom: 15 }}>{title}</Text>
      <Text style={{ color: '#3b3838', fontSize: '1rem' }}>{bio}</Text>
      {bio2 && <Text style={{ color: '#3b3838', fontSize: '1rem', marginTop: 15 }}>{bio2}</Text>}
    </div>
  </div>
);

const About: React.FC<{ active?: boolean }> = ({ active = false }) => {
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [showScrollPrompt, setShowScrollPrompt] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [scheduleCallModalOpen, setScheduleCallModalOpen] = useState(false);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    // Directly update the video element's muted property
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
    }
  };

  // Load the video and then fade in the video while fading out the logo and background
  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  useEffect(() => {
    // Show 'Scroll Down' prompt after 15 seconds
    const timer = setTimeout(() => setShowScrollPrompt(true), 25000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh', backgroundColor: 'black' }}>
      {active && (
        <video
          autoPlay
          loop
          muted={isMuted}
          onLoadedData={handleVideoLoad}
          style={{ width: '100%', height: '100%', objectFit: 'contain', position: 'absolute', transition: 'opacity 2s', opacity: videoLoaded ? 1 : 0 }}
          src={videoUrl}
          ref={videoRef}
        />
      )}

      {/* <Transition mounted={!videoLoaded} transition="fade" duration={2000} timingFunction="ease">
        {(styles) => (
          <img
            src={logo}
            alt="Logo"
            style={{
              ...styles,
              width: '100%',
              maxWidth: '1200px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          />
        )}
      </Transition> */}
      <ActionIcon
        onClick={toggleMute}
        size="xl"
        style={{
          position: 'absolute',
          zIndex: 2,
          right: '20px',
          bottom: '20px',
          background: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          opacity: 0.8,
        }}
        variant="filled"
      >
        <FontAwesomeIcon size={'xl'} icon={isMuted ? faVolumeMute : faVolumeUp} />
      </ActionIcon>

      <Transition mounted={showScrollPrompt} transition="slide-up" duration={1500} timingFunction="ease">
        {(styles) => (
          <div style={{ ...styles, position: 'absolute', bottom: '20px', width: '100%', textAlign: 'center', color: 'white', opacity: 0.5, zIndex: 2 }}>
            <FontAwesomeIcon size={'xl'} icon={faChevronDown} />
          </div>
        )}
      </Transition>


      <Container style={{ zIndex: 2, position: 'relative' }}>
        <div style={{ marginTop: '100vh' }}>
          <Title c="black" order={1} style={{ fontSize: 'clamp(1rem, 8vw, 4rem)', textAlign: 'center', paddingTop: 70 }}>TRUSTED TEAM</Title>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'black', margin: 'auto', padding: '100px 20px 0' }}>
            <TeamMemberCard
              image={thumbnailGaryG}
              name="GARY GENTRY"
              title="CHIEF EXECUTIVE OFFICER"
              bio={"Gary is a seasoned executive with a track record of driving growth and successful exits in VC and PE-backed high-growth companies. Specializing in SaaS and technology sectors, he has led teams to remarkable sales achievements across various domestic and international market segments. His expertise in go-to-market strategies and building high-performing sales teams has propelled companies rapidly to success."}
            />
            <TeamMemberCard
              image={thumbnailNephiC}
              name="NEPHI CASUGA"
              title="FOUNDER"
              bio="Nephi C. brings 18+ years of experience as a serial entrepreneur, renowned for his unwavering commitment to innovation, creativity, and the art of constructing winning organizations. His journey in the entrepreneurial landscape is marked by a steadfast dedication to fostering a culture of trust and development."
              bio2="In the dynamic realm of business, Nephi has carved a niche for himself by consistently pushing the boundaries of innovation. As a stalwart figure in the entrepreneurial arena, Nephi Casuga's narrative is one of experience, resilience, and a resolute commitment to building lasting legacies."
              reverse={true}
            />
            <TeamMemberCard
              image={thumbnailPatrickF}
              name="PATRICK FOLEY"
              title="NIL & COLLECTIVE NEGOTIATOR"
              bio="Patrick F. stands as a trailblazer and authority in the rapidly evolving landscape of Name, Image, and Likeness (NIL) within high school and collegiate sports. With an extensive and distinguished career, Patrick has seamlessly woven his expertise into the fabric of elite college football coaching staffs and collectives, leaving an indelible mark on the intersection of athletics and business. Patrick's journey has been marked by a deep commitment to empowering athletes to harness their personal brand value, catalyzing transformative opportunities in the NIL space."
            />
            <TeamMemberCard
              image={thumbnailChrisM}
              name="CHRIS MILLER"
              title="DIRECTOR OF RECRUITING"
              bio={"Chris Miller stands as the distinguished Director of Recruiting, leveraging a rich tapestry of experiences that blend professional athletics, coaching, and strategic development in youth and professional sports. His journey through the competitive realms of football has not only defined his career but also shaped his approach to team building, mentorship, and leadership."}
              bio2={"Miller's athletic prowess was first recognized at the University of Oregon, where he served as a formidable quarterback from 1983 to 1986, setting the stage for his selection as the 13th overall pick in the first round of the 1987 NFL Draft by the Atlanta Falcons. His illustrious NFL career spanned over a decade (1987-1999), during which he showcased his talent with the Atlanta Falcons, LA/St. Louis Rams, and Denver Broncos. His commitment to football transcended his playing years, evidenced by his coaching endeavors, including a four-year stint coaching professional football. He dedicated three years as a quarterback coach for the Arizona Cardinals and one year as the offensive coordinator for the XFL Houston Roughnecks."}
              reverse={true}
            />
            {/* <TeamMemberCard
              image={thumbnailBigDave}
              name="DAVID UIAGALELEI"
              title="RECRUITING MANAGER"
              bio={"David Uiagalelei, also known as \"Big Dave,\" is an expert in the evolving realm of Name, Image, and Likeness (NIL). With a proven track record in navigating the complexities of NIL, he brings a wealth of experience and insight to the forefront. As the proud father of two exceptional five-star football athletes, Big Dave has witnessed the transformative power of NIL opportunities firsthand. His son, DJ Uiagalelei, was the first to secure a groundbreaking NIL contract with industry giants like Beats by Dre and Dr. Pepper."}
              bio2={"His experience reflects years of hands-on involvement in shaping the careers of elite athletes, guiding them through the intricate web of endorsements, sponsorships, and personal brand development. In a world where the intersection of sports and commerce is increasingly complex, Big Dave stands as a beacon of knowledge, offering sage advice to athletes and their families on how to navigate and avoid pitfalls. He is a trusted advisor for those seeking success in the era of Name, Image, and Likeness."}
            /> */}
            <TeamMemberCard
              image={thumbnailBriannaG}
              name="BRIANNA GREENHALGH"
              title="CHIEF FINANCIAL OFFICER"
              bio={"Brianna holds a Bachelor of Science degree from Brigham Young University and brings a wealth of experience. Her past roles encompass accounting, operations, and analytics at both small and large companies and include working as a Legal Entity Controller at Goldman Sachs. Widely recognized for her expertise in financial planning, Brianna is eager to assist our NIL athletes. When she's not working on a spreadsheet, Brianna enjoys being outdoors with her kids, reading, and hiking."}
            />
            {/* <TeamMemberCard
              image={thumbnailKeithA}
              name="KEITH AKINA"
              title="DIRECTOR OF MARKETING"
              bio={"With an impressive tenure exceeding a decade at NIKE, Keith stands out as a trusted professional and marketing genius, having collaborated with renowned athletes such as LeBron James, Kevin Durant, Odell Beckham Jr., Drake, and Travis Scott. His marketing prowess is complemented by a solid foundation in collegiate football, having been a player at the University of Houston."}
              bio2={"Beyond his corporate achievements, Keith's strategic insights extend to the realm of coaching, where he has successfully cultivated enduring relationships with numerous programs and coaches across Div 1, Div 2, and Juco levels during his time at Western Oregon University. Hailing from Inglewood, California, Keith's journey is a testament to his marketing acumen and trusted presence in the sports industry. Currently residing in Beaverton, Oregon, he continues to bring his expertise to new heights at Nike and NILX Management group."}
              reverse={true}
            />
            <TeamMemberCard
              image={thumbnailJasonH}
              name="JASON HABERMAN"
              title="CREATIVE DIRECTOR"
              bio={"For the past 15 years, Jason Haberman has been a creative force in the industry, strategically shaping campaigns across music, sports, and entertainment. With a diverse background spanning Arista Records, Weedmaps, Atlantic Records, Red Bull Records, Vibe Magazine, Nike, Jordan Brand, Concord Records, Blackbook Magazine, and MSG Network."}
              bio2={"Jason brings a unique blend of creative vision and marketing savvy to every project. As a seasoned Creative Director, he has a proven track record of developing innovative concepts that resonate with target audiences and drive brand success."}
            /> */}
          </div>
        </div>
      </Container>

      <div style={{ textAlign: 'center', paddingBottom: '3rem' }} onClick={() => setScheduleCallModalOpen(true)}>
        <span style={{ cursor: 'pointer', display: 'inline-block', fontSize: '1.25rem', color: '#282828' }}>
          Schedule a Call Today <FontAwesomeIcon icon={faChevronRight} />
        </span>
      </div>

      <ScheduleCallModal isOpen={scheduleCallModalOpen} onClose={() => setScheduleCallModalOpen(false)} />
    </div>
  );
};

export default About;
