import React, { useEffect, useRef, useState } from 'react';
import { Container, Title, ActionIcon, Transition, Text, Image } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
// import logo from '../../assets/images/LOGO_SHADOW.png';

import soloJayden from '../../assets/images/Testimonial_Jayden_Solo.png';
import nameJayden from '../../assets/images/Testimonial_Jayden_Name.png';
import soloPoncho from '../../assets/images/Testimonial_Poncho_Solo.png';
import namePoncho from '../../assets/images/Testimonial_Poncho_Name.png';
import soloHelaman from '../../assets/images/Testimonial_Helaman_Solo.png';
import nameHelaman from '../../assets/images/Testimonial_Helaman_Name.png';
import soloSalesi from '../../assets/images/Testimonial_Salesi_Solo.png';
import nameSalesi from '../../assets/images/Testimonial_Salesi_Name.png';
import ScheduleCallModal from './ScheduleCallModal';

const videoUrl = 'https://ucarecdn.com/b6e2434e-7827-43f6-8709-6ffe47967a14/video/-/format/mp4/-/quality/normal/';


const TestimonyCard: React.FC<{
  image: string
  name: string;
  testimony: string;
  reverse?: boolean;
}> = ({ image, name, testimony, reverse }) => (
  <div style={{ display: 'flex', justifyContent: 'center', flexDirection: reverse ? 'row-reverse' : 'row', gap: '40px', marginBottom: '20px', flexWrap: 'wrap', maxWidth: '85%', margin: '0 auto 100px' }}>
    <Image src={image} alt={name} width={'auto'} height={500} />
    <div style={{ flex: 3, minWidth: 300 }}>
      <Image src={name} alt={name} width={'auto'} height={170} fit='contain' />
      <Text style={{ color: '#3b3838', fontSize: '1rem', marginTop: 40 }}>{testimony}</Text>
    </div>
  </div>
);

const Testimonials: React.FC<{ active?: boolean }> = ({ active = false }) => {
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [showScrollPrompt, setShowScrollPrompt] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [scheduleCallModalOpen, setScheduleCallModalOpen] = useState(false);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    // Directly update the video element's muted property
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
    }
  };

  // Load the video and then fade in the video while fading out the logo and background
  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  useEffect(() => {
    // Show 'Scroll Down' prompt after 15 seconds
    const timer = setTimeout(() => setShowScrollPrompt(true), 25000);
    return () => clearTimeout(timer);
  }, []);

  return (
    // <div style={{ position: 'relative', width: '100%', height: '100vh', backgroundColor: 'black' }}>
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      {/* {active && (
        <video
          autoPlay
          loop
          muted={isMuted}
          onLoadedData={handleVideoLoad}
          style={{ width: '100%', height: '100%', objectFit: 'contain', position: 'absolute', transition: 'opacity 2s', opacity: videoLoaded ? 1 : 0 }}
          src={videoUrl}
          ref={videoRef}
        />
      )} */}

      {/* <Transition mounted={!videoLoaded} transition="fade" duration={2000} timingFunction="ease">
        {(styles) => (
          <img
            src={logo}
            alt="Logo"
            style={{
              ...styles,
              width: '100%',
              maxWidth: '1200px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          />
        )}
      </Transition> */}
      {/* <ActionIcon
        onClick={toggleMute}
        size="xl"
        style={{
          position: 'absolute',
          zIndex: 2,
          right: '20px',
          bottom: '20px',
          background: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          opacity: 0.8,
        }}
        variant="filled"
      >
        <FontAwesomeIcon size={'xl'} icon={isMuted ? faVolumeMute : faVolumeUp} />
      </ActionIcon> */}

      {/* <Transition mounted={showScrollPrompt} transition="slide-up" duration={1500} timingFunction="ease">
        {(styles) => (
          <div style={{ ...styles, position: 'absolute', bottom: '20px', width: '100%', textAlign: 'center', color: 'white', opacity: 0.5, zIndex: 2 }}>
            <FontAwesomeIcon size={'xl'} icon={faChevronDown} />
          </div>
        )}
      </Transition> */}

      <Container style={{ zIndex: 2, position: 'relative' }}>
        {/* <div style={{ marginTop: '100vh' }}> */}
        <div>
          <Title order={1} c="black" style={{ fontSize: 'clamp(1rem, 8vw, 4rem)', textAlign: 'center', paddingTop: 70 }}>EMPOWERING ATHLETES</Title>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto', padding: '100px 20px 0' }}>
            {/* <TestimonyCard image={soloJayden} name={nameJayden} reverse testimony={"Entering the portal was not an easy task. I didn't know who to talk to or know where to start. I was blessed to have Uncle Pat and Big Dave from NILX step right in to help me through the process. They made the drive to Las Vegas multiple times, sat down with my family and walked me through step by step. Always making sure my family and I felt good about every decision I made. Today I'm incredibly blessed to be a Trojan, the first Polynesian QB and in a financial position to help my family. Thank you NILX!"} /> */}
            <TestimonyCard image={soloPoncho} name={namePoncho} testimony={"Starting as a true freshman was not an easy task. It requires lots of hard work on and off the field and more importantly it requires lots of time. Learning about NIL and how I can start earning money didn't make it any easier. This also required time, lots of it. Today I can honestly say NILX helped negotiate my collective deal and handles all my NIL opportunities. I meet with them weekly. They feel like family. If I have any questions or need anything someone is always there. This gives me time to focus on my development so I can be the best athlete possible."} />
            <TestimonyCard image={soloHelaman} name={nameHelaman} reverse testimony={"Parent: I'm incredibly grateful that NILX is educating my son about his football career through their online courses. The courses are helping him take control of his career now. Helping him setup an LLC, owning his domain to name a few. This is a game changer for our family and I believe a much needed service for young athletes."} />
            <TestimonyCard image={soloSalesi} name={nameSalesi} testimony={"Parent: Within a few weeks of signing, NILX quickly connected us with D1 coaches across the country. They assisted in helping my son secure his 7th offer with Washington and we anticipate many more opportunities. Their recruiting staff is approachable, easy to work with and well connected."} />
          </div>
        </div>
      </Container>

      <div style={{ textAlign: 'center', paddingBottom: '3rem' }} onClick={() => setScheduleCallModalOpen(true)}>
        <span style={{ cursor: 'pointer', display: 'inline-block', fontSize: '1.25rem', color: '#282828' }}>
          Schedule a Call Today <FontAwesomeIcon icon={faChevronRight} />
        </span>
      </div>

      <ScheduleCallModal isOpen={scheduleCallModalOpen} onClose={() => setScheduleCallModalOpen(false)} />
    </div>
  );
};

export default Testimonials;
