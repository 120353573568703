import { useState, useEffect } from 'react';
import { Input as MantineInput, NativeSelect } from '@mantine/core';
import { DateInput, DateValue } from '@mantine/dates';
import '@mantine/dates/styles.css';

export type InputTypes = 'text' | 'phone' | 'email' | 'dropdown' | 'date';

export default function Input({
  style,
  size,
  label,
  placeholder,
  value,
  setValue,
  description,
  error,
  required,
  type = 'text',
  data,
  dark,
  className,
  ...props
}: {
  style?: React.CSSProperties;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  label?: string;
  placeholder?: string;
  value?: string;
  setValue?: (value: string, error: string) => void; // Added error parameter to setValue function
  description?: string;
  error?: string;
  required?: boolean;
  type?: InputTypes;
  dark?: boolean;
  className?: string;
  data?: { label: string; value: string; disabled?: boolean }[];
}) {
  const [inputValue, setInputValue] = useState(value || '');
  const [inputError, setInputError] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    const error = validate(newValue, type);
    setInputError(error);
    if (setValue) {
      setValue(newValue, error);
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    const error = validate(newValue, type);
    setInputError(error);
    if (setValue) {
      setValue(newValue, error);
    }
  };

  const handleDateChange = (date: DateValue) => {
    const newValue = date?.toISOString();
    if (newValue) {
      setInputValue(newValue);
      const error = validate(newValue, type);
      setInputError(error);
      if (setValue) {
        setValue(newValue, error);
      }
    }
  }

  // Validate function
  const validate = (value: string, type: InputTypes): string => {
    let error = '';
    if (type === 'email') {
      const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (!emailRegex.test(value)) {
        error = 'Invalid email address';
      }
    } else if (type === 'phone') {
      const phoneRegex = /^\d{10}$/;
      if (!phoneRegex.test(value)) {
        error = 'Invalid phone number';
      }
    }
    return error;
  };

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value || '');
    }
    // eslint-disable-next-line
  }, [value]);

  if (type === 'dropdown') {
    return (
      <NativeSelect
        className={className}
        variant="filled"
        label={label}
        placeholder={placeholder}
        error={error || inputError}
        description={description}
        data={data}
        required={required}
        value={inputValue}
        onChange={handleSelectChange}
        radius={8}
        styles={{
          label: {
            color: 'white',
            fontWeight: 'bold',
            fontFamily: 'Heebo, sans-serif',
          },
          required: {
            color: 'white'
          },
          input: {
            backgroundColor: 'rgba(80, 80, 80, 0.6)',
            fontFamily: 'Heebo, sans-serif',
            fontWeight: 'bold',
            color: 'white',
          }
        }}
        {...props}
      />
    )
  } else if (type === 'date') {
    // const initialDate = subYears(new Date(), 18); // Set the initial date to 18 years ago

    return (
      <DateInput
        className={className}
        variant="filled"
        label={label}
        placeholder={placeholder}
        error={error || inputError}
        description={description}
        required={required}
        defaultLevel={'decade'}
        value={inputValue ? new Date(inputValue) : undefined}
        onChange={handleDateChange}
        radius={8}
        styles={{
          label: {
            color: 'white',
            fontWeight: 'bold',
            fontFamily: 'Heebo, sans-serif',
          },
          required: {
            color: 'white'
          },
          input: {
            backgroundColor: 'rgba(80, 80, 80, 0.6)',
            fontFamily: 'Heebo, sans-serif',
            fontWeight: 'bold',
            color: 'white',
          },
        }}
        {...props}
      />
    );
  }

  return (
    <MantineInput.Wrapper
      className={className}
      label={label}
      description={description}
      error={error || inputError}
      required={required}
      style={style}
      styles={{
        label: {
          color: 'white',
          fontWeight: 'bold',
          fontFamily: 'Heebo, sans-serif',
        },
        required: {
          color: 'white'
        }
      }}
    >
      <MantineInput
        variant="filled"
        type={type}
        radius={size ? 6 : 8}
        size={size}
        styles={(theme) => ({
          input: dark ? {
            backgroundColor: '#2b2b2b',
            fontFamily: 'Heebo, sans-serif',
            fontWeight: 'bold',
            color: 'white',
            borderWidth: '0.1px',
            borderColor: '#EEEEEE88',
          } : {
            backgroundColor: 'rgba(80, 80, 80, 0.6)',
            fontFamily: 'Heebo, sans-serif',
            fontWeight: 'bold',
            color: 'white',
          },
        })}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
        {...props}
      />
    </MantineInput.Wrapper>
  );
}