import React, { useState } from 'react';
import { Modal, Textarea } from '@mantine/core';
import Input from '../atoms/Input'; // Assuming this component has props like `value`, `setValue`, and accepts `type` prop for email, etc.
import Button from '../atoms/Button'; // Assuming this Button accepts props like `loading`, `disabled`, `onClick`, etc.

interface ScheduleCallModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ScheduleCallModal: React.FC<ScheduleCallModalProps> = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('I would like to schedule a call.');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [honeyPot, setHoneyPot] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const formData = {
      name,
      phone,
      email,
      message,
      honey_pot: honeyPot,
    };

    try {
      if (honeyPot) {
        setError(true);
        setLoading(false);
        console.error('Form submission failed: Honey pot field was filled out');
        return;
      }

      // Replace this URL with your desired endpoint or keep it if you're updating the Zapier webhook later
      const response = await fetch('https://hooks.zapier.com/hooks/catch/17024038/3xtoi9f/', {
        method: 'POST',
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitted(true);
        console.log('Form submitted successfully');
      } else {
        setError(true);
        console.error('Form submission failed');
      }
    } catch (error) {
      setError(true);
      console.error('An error occurred while submitting the form', error);
    }
    setLoading(false);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={error ? "Error" : submitted ? "Thank You!" : "Schedule a Call Today!"}
      size="md"
      styles={{
        content: {
          backgroundColor: '#2b2b2b',
          borderRadius: '10px',
          padding: '20px',
        },
        header: {
          background: '#2b2b2b',
          color: 'white',
          fontWeight: 'bold',
          fontFamily: 'Heebo, sans-serif',
          textAlign: 'center',
        },
        title: {
          background: '#2b2b2b',
          color: 'white',
          fontSize: '1.25rem',
          fontWeight: 'bold',
          fontFamily: 'Heebo, sans-serif',
        },
        close: {
          color: '#EDEDED',
        },
      }}
    >
      {error ? (
        <div style={{ color: 'white', textAlign: 'center' }}>
          An error occurred while submitting the form. Please try again later.
        </div>
      ) : submitted ? (
        <div style={{ color: 'white', textAlign: 'center' }}>
          Thank you for reaching out. We will contact you soon!
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <Input
            style={{ marginBottom: 10 }}
            placeholder="Your Name"
            label="Name"
            required
            dark
            size='xs'
            value={name}
            setValue={setName}
          />
          <Input
            style={{ marginBottom: 10 }}
            placeholder="Your Email"
            label="Email"
            required
            dark
            size='xs'
            value={email}
            setValue={setEmail}
          />
          <Input
            style={{ marginBottom: 10 }}
            placeholder="Your Phone Number"
            label="Phone"
            dark
            size='xs'
            value={phone}
            setValue={setPhone}
          />
          <Textarea
            label="Message"
            placeholder="Your message"
            value={message}
            onChange={(event) => setMessage(event.currentTarget.value)}
            styles={(theme) => ({
              input: {
                backgroundColor: '#2b2b2b',
                fontFamily: 'Heebo, sans-serif',
                fontWeight: 'bold',
                color: 'white',
                borderWidth: '0.1px',
                borderColor: '#EEEEEE88',
              },
              label: {
                fontFamily: 'Heebo, sans-serif',
                fontWeight: 'bold',
                color: 'white',
              }
            })}
            mt="sm"
          />
          <input type="hidden" name="honey_pot" value={honeyPot} onChange={(e) => setHoneyPot(e.target.value)} />
          <Button
            type="submit" // Add this type attribute
            fullWidth
            loading={loading}
            disabled={loading || submitted || error || !name || !email}
            style={{ marginTop: 20, border: '0.01rem solid #EEEEEE88' }}
            color="rgba(30, 30, 30, 1)"
          >
            SUBMIT
          </Button>
        </form>
      )}
    </Modal>
  );
};

export default ScheduleCallModal;