import { MantineProvider, createTheme } from '@mantine/core';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './screens/LandingPage';
import AgentRegistrations from './screens/AgentRegistrations';
import Disclosures from './screens/Disclosures';

import './App.css';
import './styles/fonts.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';

function App() {
  const theme = createTheme({
    primaryColor: 'black',
    colors: {
      purple: ["#7c33d6", "#7229cc", "#681fc2", "#5e15b8", "#540bae", "#4a01a4", "#40009a", "#360090", "#2c0086", "#22007c"],
      teal: ["#28c5c5", "#24baba", "#20b0b0", "#1ca5a5", "#189b9b", "#148f8f", "#108484", "#0c7a7a", "#086f6f", "#046565"],
      black: ['#000000', '#0a0a0a', '#141414', '#1e1e1e', '#282828', '#323232', '#bababa', '#464646', '#505050', '#5a5a5a'],
    },
    fontFamily: 'Heebo, sans-serif',
    headings: {
      fontFamily: 'Railroad Gothic ATF, sans-serif',
    }
  });

  return (
    <MantineProvider theme={theme} withCssVariables defaultColorScheme="light">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<LandingPage />} />
          <Route path="/about" element={<LandingPage defaultComponent='About' />} />
          <Route path="/athletes" element={<LandingPage defaultComponent='Athletes' />} />
          <Route path="/testimonials" element={<LandingPage defaultComponent='Testimonials' />} />
          <Route path="/register" element={<Navigate to="/home" />} />
          <Route path="/prospectives" element={<Navigate to="/home" />} />
          <Route path="/prospects" element={<Navigate to="/home" />} />
          <Route path="/agentregistrations" element={<AgentRegistrations />} />
          <Route path="/disclosures" element={<Disclosures />} />
        </Routes>
      </Router>
    </MantineProvider>
  );
}

export default App;
