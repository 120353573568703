import React, { useEffect, useRef, useState } from 'react';
import { Anchor, Container, Group } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Home from './../components/landing-page/Home';
import About from './../components/landing-page/About';
import Athletes from './../components/landing-page/Athletes';
// import Register from './../components/landing-page/Register';
import Testimonials from './../components/landing-page/Testimonials';

import BackgroundImage from './../assets/images/Background_lowres.png';
import LogoImage from './../assets/images/NILX_X.png';
import NewsletterModal from '../components/landing-page/NewsletterModal';
import { useDisclosure } from '@mantine/hooks';

const ANIMATION_DURATION = 1500;
const NEWSLETTER_REQUEST_DELAY = 15000;

export type ContentTypes = 'Home' | 'Athletes' | 'Testimonials' | 'About' | 'News';
const videoTypes: ContentTypes[] = ['About', 'Testimonials'];
// const videoTypes: ContentTypes[] = ['About'];
const componentOrder: ContentTypes[] = ['Home', 'Athletes', 'Testimonials', 'About', 'News'];
const components: { [key in ContentTypes]: React.FC<{ active?: boolean }> } = {
  Home: Home,
  About: About,
  Athletes: Athletes,
  // Register: Register,
  Testimonials: Testimonials,
  News: () => null,
};

const LandingPage: React.FC<{ defaultComponent?: ContentTypes }> = ({ defaultComponent = 'Home' }) => {
  const [currentComponent, setCurrentComponent] = useState<ContentTypes>(defaultComponent);
  const [incomingComponent, setIncomingComponent] = useState<ContentTypes | null>(null);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [alreadyAskedForNewsletter, setAlreadyAskedForNewsletter] = useState<boolean>(false);

  const [textColor, setTextColor] = useState<string>('');
  const [navActiveColor, setNavActiveColor] = useState<string>('');
  const [navColor, setNavColor] = useState<string>('');
  const [socialBottom, setSocialBottom] = useState<number>(0);
  const [socialOpacity, setSocialOpacity] = useState<number>(1);

  const [opened, { close, open }] = useDisclosure();
  const viewRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setScrollPosition(e.currentTarget.scrollTop);
  };

  const changeComponent = (newComponent: ContentTypes) => {
    if (newComponent === 'News') {
      open();
    } else if (newComponent !== currentComponent && !incomingComponent) {
      setIncomingComponent(newComponent);
    }
  };

  useEffect(() => {
    if (incomingComponent) {
      navigate('/' + (incomingComponent !== 'Home' ? incomingComponent.toLowerCase() : ''));

      setTimeout(() => {
        setCurrentComponent(incomingComponent);
        setIncomingComponent(null);
      }, ANIMATION_DURATION);
    }
    // eslint-disable-next-line
  }, [incomingComponent]);

  // Open newsletter modal after delay
  useEffect(() => {
    if (!alreadyAskedForNewsletter) {
      setTimeout(() => {
        open();
        setAlreadyAskedForNewsletter(true);
      }, NEWSLETTER_REQUEST_DELAY);
    }
  }, [open, alreadyAskedForNewsletter]);

  const centeredContentStyle: React.CSSProperties = {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
    transition: `left ${ANIMATION_DURATION}ms ease-in-out`,
  };

  const logoStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: `${componentOrder.indexOf(incomingComponent || currentComponent) * -15}%`,
    transform: 'translateY(-50%)',
    height: '140vh',
    zIndex: 1,
    transition: `left ${ANIMATION_DURATION}ms ease-in-out`,
  };

  useEffect(() => {
    const isVideoComponent = videoTypes.indexOf(currentComponent) > -1;
    setTextColor(!incomingComponent && isVideoComponent ? '#CCC' : '#282828');
    setNavActiveColor(!incomingComponent && isVideoComponent && currentComponent !== 'Testimonials' ? 'white' : 'black');
    setNavColor(!incomingComponent && isVideoComponent && currentComponent !== 'Testimonials' ? '#CCC' : '#666');
    setSocialBottom(videoTypes.indexOf(incomingComponent ?? 'Home') > -1 || (!incomingComponent && isVideoComponent) ? -40 : 40);
    setSocialOpacity(videoTypes.indexOf(incomingComponent ?? 'Home') > -1 || (!incomingComponent && isVideoComponent) ? 0 : 1);
  }, [currentComponent, incomingComponent]);

  return (
    <div style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', minHeight: '100vh', maxHeight: '100vh', position: 'relative', overflow: 'hidden' }}>
      {/* Background X */}
      <img src={LogoImage} alt="NILX Logo" style={logoStyle} />

      {/* Navbar */}
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', position: 'fixed', top: 0 - scrollPosition, left: 0, right: 0, zIndex: 10 }}>
        <div style={{ width: '85%', textAlign: 'center', justifyContent: 'space-around', display: 'flex', flexWrap: 'wrap', gap: 10, paddingTop: 20 }}>
          {/* Navbar Links */}
          {componentOrder.map((component) => (
            <Anchor
              key={component}
              style={{
                fontWeight: '900',
                fontFamily: 'Heebo, sans-serif',
                color: (incomingComponent === component && incomingComponent !== null) || (currentComponent === component && incomingComponent === null) ? navActiveColor : navColor,
                transition: 'color 300ms',
                textDecoration: 'none',
                paddingTop: 0
              }}
              className="navbar-link"
              onClick={() => changeComponent(component)}
            >
              {component.toUpperCase()}
            </Anchor>
          ))}
        </div>
      </Container>

      {/* Content Area */}
      <div style={{ position: 'relative', width: '100%', height: '100vh', top: 0, zIndex: 2 }}>
        {Object.entries(components).map(([key, Component]) => {
          // Check if key is left or right of incoming component
          const currentComponentIndex = componentOrder.indexOf(currentComponent);
          const incomingComponentIndex = incomingComponent ? componentOrder.indexOf(incomingComponent) : currentComponentIndex;
          const keyIndex = componentOrder.indexOf(key as ContentTypes);

          // If key is left of incoming component, set left to -100%
          // If key is right of incoming component, set left to 100%
          // If key is incoming component, set left to 0
          const left = keyIndex < incomingComponentIndex ? '-100%' : keyIndex > incomingComponentIndex ? '100%' : '0';

          // Set visible only if key is current or incoming component
          const visible: 'visible' | 'hidden' = keyIndex === currentComponentIndex || keyIndex === incomingComponentIndex ? 'visible' : 'hidden';

          return (
            <div key={key} ref={viewRef} style={{ ...centeredContentStyle, left: left, visibility: visible, overflowY: 'scroll' }} onScroll={handleScroll}>
              <Component active={visible === 'visible'} />
            </div>
          )
        })}
      </div>

      {/* Social Links */}
      <Container style={{ transition: currentComponent === 'Home' && !incomingComponent ? undefined : '1.5s all', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', position: 'fixed', bottom: socialBottom + scrollPosition, left: 0, right: 0, zIndex: 10, opacity: socialOpacity }}>
        <Group justify="space-around" wrap="nowrap" style={{ width: '80%', textAlign: 'center', maxWidth: 400 }}>
          <Anchor href="https://www.facebook.com/nilxgroupllc" target="_blank"><FontAwesomeIcon icon={faFacebookF} mask={faCircle} color={textColor} transform="shrink-6" size="2x" /></Anchor>
          <Anchor href="https://www.instagram.com/nilxgroup1/" target="_blank"><FontAwesomeIcon icon={faInstagram} mask={faCircle} color={textColor} transform="shrink-4" size="2x" /></Anchor>
          <Anchor href="https://twitter.com/nilxgroup" target="_blank"><FontAwesomeIcon icon={faTwitter} mask={faCircle} color={textColor} transform="shrink-6" size="2x" /></Anchor>
          <Anchor href="https://www.youtube.com/@NILXGROUP" target="_blank"><FontAwesomeIcon icon={faYoutube} mask={faCircle} color={textColor} transform="shrink-6" size="2x" /></Anchor>
          <Anchor href="mailto:info@nilx.com" target="_blank"><FontAwesomeIcon icon={faEnvelope} mask={faCircle} color={textColor} transform="shrink-6" size="2x" /></Anchor>
        </Group>
      </Container>

      {/* Newsletter Modal */}
      <NewsletterModal isOpen={opened} onClose={close} />
      {/* Contact us modal for About, Services, and Testimonials  */}
    </div >
  );
}

export default LandingPage;
