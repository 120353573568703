import React, { useState } from 'react';
import { Modal } from '@mantine/core';
import Input from '../atoms/Input';
import Button from '../atoms/Button';

interface NewsletterModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewsletterModal: React.FC<NewsletterModalProps> = ({ isOpen, onClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [honeyPot, setHoneyPot] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const formData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      honey_pot: honeyPot,
    };

    try {
      if (honeyPot) {
        setError(true);
        setLoading(false);
        console.error('Form submission failed: Honey pot field was filled out');
        return;
      }

      const response = await fetch('https://hooks.zapier.com/hooks/catch/17024038/3amcvwb/', {
        method: 'POST',
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitted(true);
        console.log('Form submitted successfully');
      } else {
        setError(true);
        console.error('Form submission failed');
      }
    } catch (error) {
      setError(true);
      console.error('An error occurred while submitting the form', error);
    }
    setLoading(false);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={error ? "Error" : submitted ? "SUCCESS!" : "SIGN UP FOR OUR NEWSLETTER!"}
      size="md"
      styles={{
        content: {
          backgroundColor: '#2b2b2b', // Your desired background color
          borderRadius: '10px',        // Your desired border radius
          padding: '0 20px',
        },
        header: {
          background: '#2b2b2b',
          color: 'white',
          fontWeight: 'bold',
          fontFamily: 'Heebo, sans-serif',
          textAlign: 'center',
        },
        title: {
          background: '#2b2b2b',
          color: 'white',
          fontSize: '1.25rem',
          fontWeight: 'bold',
          fontFamily: 'Heebo, sans-serif',
        },
        close: {
          color: '#EDEDED',
        }
      }}
    >
      {error ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: 'white' }}>
          <h3 style={{ marginBottom: 10 }}>Error occurred while submitting the form: Please try again later.</h3>
        </div>
      ) : submitted ? (
        <p style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Heebo, sans-serif' }}>Thank you for signing up. You'll here from us soon!</p>
      ) : (
        <form className="newsletter-form" onSubmit={handleSubmit}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 15, marginBottom: 10 }}>
            <Input
              style={{ flex: 1 }}
              placeholder="First Name"
              label="First Name"
              required
              dark
              size='xs'
              value={firstName}
              setValue={(val, err) => setFirstName(val)}
            />
            <Input
              style={{ flex: 1 }}
              placeholder="Last Name"
              label="Last Name"
              required
              dark
              size='xs'
              value={lastName}
              setValue={(val, err) => setLastName(val)}
            />
          </div>
          <Input
            style={{ flex: 1 }}
            placeholder="Email"
            label="Email"
            required
            dark
            size='xs'
            type="email"
            value={email}
            setValue={(val, err) => setEmail(val)}
          />
          <input type="hidden" name="honey_pot" value={honeyPot} onChange={(e) => setHoneyPot(e.target.value)} />
          <Button
            type="submit" // Add this type attribute
            fullWidth
            loading={loading}
            disabled={loading || submitted || error || !firstName || !lastName || !email}
            style={{ marginTop: 20, marginBottom: 20, border: '0.01rem solid #EEEEEE88' }}
            color="rgba(30, 30, 30, 1)"
          >
            SUBMIT
          </Button>
        </form>
      )}
    </Modal>
  );
};

export default NewsletterModal;
