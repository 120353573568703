import React from 'react';
import { Text, Title, Divider, List } from '@mantine/core';

import BackgroundImage from './../assets/images/Background_lowres.png';

const AgentRegistrations: React.FC = () => {
  const states = [
    { name: 'Alabama', note: '' },
    { name: 'Alaska', note: 'no state agent registration required' },
    { name: 'Arizona', note: 'ASU and U of AZ; NAU not required' },
    { name: 'Arkansas', note: '' },
    { name: 'Colorado', note: 'no state agent registration required' },
    { name: 'Connecticut', note: 'no state agent registration required' },
    { name: 'Delaware', note: '' },
    { name: 'Florida', note: '' },
    { name: 'Hawaii', note: '' },
    { name: 'Idaho', note: 'no state agent registration required' },
    { name: 'Illinois', note: 'no state agent registration required' },
    { name: 'Indiana', note: '' },
    { name: 'Iowa', note: '' },
    { name: 'Kansas', note: '' },
    { name: 'Kentucky', note: '' },
    { name: 'Louisiana', note: 'no state agent registration required' },
    { name: 'Maine', note: 'no state agent registration required' },
    { name: 'Massachusetts', note: '' },
    { name: 'Michigan', note: 'no state agent registration required' },
    { name: 'Minnesota', note: '' },
    { name: 'Mississippi', note: '' },
    { name: 'Missouri', note: '' },
    { name: 'Montana', note: '' },
    { name: 'Nevada', note: '' },
    { name: 'New Jersey', note: 'no state agent registration required' },
    { name: 'New Mexico', note: '' },
    { name: 'North Dakota', note: '' },
    { name: 'Ohio', note: '' },
    { name: 'Oklahoma', note: '' },
    { name: 'Oregon', note: '' },
    { name: 'Rhode Island', note: '' },
    { name: 'South Dakota', note: '' },
    { name: 'Tennessee', note: '' },
    { name: 'Texas', note: '' },
    { name: 'Utah', note: '' },
    { name: 'Vermont', note: 'no state agent registration required' },
    { name: 'Virginia', note: '' },
    { name: 'Washington', note: 'U of WA' },
    { name: 'West Virginia', note: '' },
  ];

  return (
    <div style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', minHeight: '100vh', maxHeight: '100vh', position: 'relative', overflow: 'scroll', padding: 20 }}>
      <div style={{ maxWidth: 700, margin: 'auto', padding: 20, background: 'white', borderRadius: 5 }}>
        <div>
          <Title order={2}>Agent Registrations</Title>
          <Text size="sm" c='dimmed'>Last updated 9/3/2024</Text>
        </div>
        <Divider my="sm" />

        <Text mb="md">We are authorized to act as sports agents in the following states:</Text>

        <List
          spacing="xs"
          size="sm"
          center
        >
          {states.map((state, index) => (
            <List.Item key={index}>
              <Text>
                <strong>{state.name}</strong>
                {state.note && (
                  <Text component="span" size="xs" c="dimmed" ml={5}>
                    ({state.note})
                  </Text>
                )}
              </Text>
            </List.Item>
          ))}
        </List>
      </div>
      <div style={{ margin: 5 }}>&nbsp;</div>
    </div>
  );
}

export default AgentRegistrations;