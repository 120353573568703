import React, { useRef, useState, useEffect } from 'react';
import logo from '../../assets/images/LOGO_SHADOW.png';
import { ActionIcon, Container, ThemeIcon, Title, Text } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { HiOutlineLightBulb } from 'react-icons/hi';
import { FaDollarSign, FaRegHandshake } from 'react-icons/fa';
import { IconType } from 'react-icons';
import ScheduleCallModal from './ScheduleCallModal'; // Adjust the import path as necessary
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const videoUrl = 'https://ucarecdn.com/4bfeb130-e32b-4034-96fa-27f1bc5d8350/video/-/format/mp4/-/quality/normal/';


const ServiceCard: React.FC<{
  title: string;
  description: string;
  icon: IconType;
}> = ({ title, description, icon: Icon }) => (
  <div style={{ display: 'flex', justifyContent: 'center', gap: '40px', marginBottom: '20px', flexWrap: 'wrap', maxWidth: '85%', margin: '0 auto 100px' }}>
    <div style={{ display: 'flex', gap: '30px', alignItems: 'flex-start', flex: 3, minWidth: 300 }}> {/* Increased gap and changed alignment to 'flex-start' */}
      <ThemeIcon color={'#444'} variant="filled" size="xl" radius="xl" style={{ margin: 10 }}>
        <Icon size={36} /> {/* Icon color changed to white and made larger */}
      </ThemeIcon>
      <div>
        <Text style={{ fontFamily: 'Hebo, sans-serif', fontSize: '2rem', fontStyle: 'italic', fontWeight: 'bold', marginBottom: 5, color: 'black' }}>{title}</Text>
        <Text style={{ color: '#3b3838', fontSize: '1rem' }}>{description}</Text>
      </div>
    </div>
  </div>
);


const Home: React.FC<{ active?: boolean }> = ({ active = false }) => {
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [scheduleCallModalOpen, setScheduleCallModalOpen] = useState(false);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
    }
  };

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (videoRef.current) {
        const { top } = videoRef.current.getBoundingClientRect();
        if (top <= window.innerHeight && !videoLoaded) {
          setVideoLoaded(true); // Start playing video when it's scrolled into view
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [videoLoaded]);

  return (
    <div style={{ flex: 1 }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <img
          src={logo}
          alt="Logo"
          style={{
            maxWidth: '1200px',
            width: '100%',
            maxHeight: '100%',
            objectFit: 'contain', // Ensure the logo is fully visible without stretching
          }}
        />
      </div>
      <div style={{ position: 'relative', width: '100%', height: '100vh', overflow: 'hidden' }}>
        <video
          autoPlay
          loop
          muted={isMuted}
          onLoadedData={handleVideoLoad}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover', // Cover the full area of the container
            position: 'absolute',
            top: 0,
            left: 0,
            display: videoLoaded ? 'block' : 'none', // Only display the video once it's loaded and scrolled into view
          }}
          src={videoUrl}
          ref={videoRef}
        />

        <ActionIcon
          onClick={toggleMute}
          size="xl"
          style={{
            position: 'absolute',
            zIndex: 10,
            right: '20px',
            bottom: '20px',
            padding: '10px',
            background: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            opacity: 0.8,
          }}
          variant="filled"
        >
          <FontAwesomeIcon size={'xl'} icon={isMuted ? faVolumeMute : faVolumeUp} />
        </ActionIcon>
      </div>

      <div style={{ position: 'absolute', bottom: '10px', width: '100%', textAlign: 'center', color: '#282828', zIndex: 2 }}>
        {/* <Title order={3}>Scroll Down</Title> */}
        <FontAwesomeIcon size={'xl'} icon={faChevronDown} />
      </div>

      <Container style={{ zIndex: 2, position: 'relative' }}>
        <Title c="black" order={1} style={{ fontSize: 'clamp(1rem, 8vw, 4rem)', textAlign: 'center', paddingTop: 70 }}>OUR NIL SOLUTION</Title>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto', padding: '100px 20px 0' }}>
          <ServiceCard title="Get Educated" icon={HiOutlineLightBulb} description="Educating athletes and families about NIL, gives our clients the upper edge when navigating their journey. This is accomplished through our online kickstarter educational series, coupled with 24/7 support." />
          <ServiceCard title="Qualified Representation" icon={FaDollarSign} description="With over a million dollars in collective contracts, we are confident in negotiating NIL collective deals for our clients. Our understanding of market prices, contracts, and managing expectations are crucial factors in delivering the best representation possible." />
          <ServiceCard title="Making Connections" icon={FaRegHandshake} description="We value the connections made over the years, ranging from college coaches, CEOs, to CPAs. Our goal is to connect experts with our athletes, all aimed at nurturing 5-star human beings and setting them up for a long life of success." />
        </div>
      </Container>

      <div style={{ textAlign: 'center', paddingBottom: '3rem' }} onClick={() => setScheduleCallModalOpen(true)}>
        <span style={{ cursor: 'pointer', display: 'inline-block', fontSize: '1.25rem', color: '#282828' }}>
          Schedule a Call Today <FontAwesomeIcon icon={faChevronRight} />
        </span>
      </div>

      <ScheduleCallModal isOpen={scheduleCallModalOpen} onClose={() => setScheduleCallModalOpen(false)} />
    </div>
  );
};

export default Home;
