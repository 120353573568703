
import React, { useEffect, useState } from 'react';
import chevron from '../../assets/images/next.png';
import krewProfile from '../../assets/images/Athlete_KrewJones_profile.png';
import krewStats from '../../assets/images/Athlete_KrewJones_stats.png';
// import jaydenProfile from '../../assets/images/Athlete_JaydenMaiava_profile.png';
// import jaydenStats from '../../assets/images/Athlete_JaydenMaiava_stats.png';
import helamanProfile from '../../assets/images/Athlete_Helaman_Casuga_profile.png';
import helamanStats from '../../assets/images/Athlete_Helaman_Casuga_stats.png';
import iapaniProfile from '../../assets/images/Athlete_Iapani_Laloulu_profile.png';
import iapaniStats from '../../assets/images/Athlete_Iapani_Laloulu_stats.png';
import raymondProfile from '../../assets/images/Athlete_RaymondPulido_profile.png';
import raymondStats from '../../assets/images/Athlete_RaymondPulido_stats.png';
import zeonProfile from '../../assets/images/Athlete_ZeonChriss_profile.png';
import zeonStats from '../../assets/images/Athlete_ZeonChriss_stats.png';
import salesiProfile from '../../assets/images/Athlete_SalesiMoa_profile.png';
import salesiStats from '../../assets/images/Athlete_SalesiMoa_stats.png';


const ANIMATION_DURATION = 1000;

const Athletes: React.FC = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [incomingImageIndex, setIncomingImageIndex] = useState<number | null>(null);
  const [swipeDirection, setSwipeDirection] = useState<'left' | 'right' | null>(null);
  const images = [{
    profile: krewProfile,
    stats: krewStats,
  }, {
    profile: helamanProfile,
    stats: helamanStats,
  }, {
    profile: iapaniProfile,
    stats: iapaniStats,
  }, {
    profile: raymondProfile,
    stats: raymondStats,
  }, {
    profile: zeonProfile,
    stats: zeonStats,
  }, {
    profile: salesiProfile,
    stats: salesiStats,
  }];

  useEffect(() => {
    if (swipeDirection) {
      let incoming = selectedImageIndex;
      if (swipeDirection === 'left') {
        incoming = selectedImageIndex === 0 ? images.length - 1 : selectedImageIndex - 1;
      } else if (swipeDirection === 'right') {
        incoming = selectedImageIndex === images.length - 1 ? 0 : selectedImageIndex + 1;
      }
      setIncomingImageIndex(incoming);

      setTimeout(() => {
        setSwipeDirection(null);
        setSelectedImageIndex(incoming);
        setIncomingImageIndex(null);
      }, ANIMATION_DURATION);
    }
  }, [swipeDirection, images.length, selectedImageIndex]);

  const previousImage = () => {
    setSwipeDirection('left');
  }

  const nextImage = () => {
    setSwipeDirection('right');
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 50 }}>
      {/* Previous Icon */}
      <img
        src={chevron}
        alt={`Previous Athlete`}
        style={{
          transform: `rotate(180deg)`,
          width: '35px',
          height: 'auto',
          opacity: 1,
          transition: 'opacity 0.3s',
          cursor: 'pointer',
        }}
        onMouseEnter={(e) => (e.currentTarget.style.opacity = '0.7')}
        onMouseLeave={(e) => (e.currentTarget.style.opacity = '1')}
        onClick={previousImage}
      />

      {/* Images */}
      <div style={{ width: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 40 }}>
        {images.map((image, index) => {
          const imageStyle: React.CSSProperties = {
            position: 'absolute',
            opacity: (index === selectedImageIndex && !swipeDirection) || index === incomingImageIndex ? 1 : 0,
            transition: `opacity ${ANIMATION_DURATION}ms ease-in-out, left ${ANIMATION_DURATION}ms ease-in-out`,
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 30,
          };
          const isLeft = index < selectedImageIndex || (selectedImageIndex === 0 && index === images.length - 1);
          const isRight = index > selectedImageIndex || (selectedImageIndex === images.length - 1 && index === 0);

          if (index === incomingImageIndex) {
            imageStyle.left = '0';
          } else if (swipeDirection === 'left') {
            if (index === selectedImageIndex) {
              imageStyle.left = '100%';
            } else if (isLeft) {
              imageStyle.left = '-100%';
            }
          } else if (swipeDirection === 'right') {
            if (index === selectedImageIndex) {
              imageStyle.left = '-100%';
            } else if (isRight) {
              imageStyle.left = '100%';
            }
          } else if (index === selectedImageIndex && !incomingImageIndex) {
            imageStyle.left = '0';
          }

          return (
            <div key={`athlete-${index}`} style={imageStyle}>
              <img
                key={`profile-${index}`}
                src={image.profile}
                alt={`Athlete ${index} profile`}
                style={{ flex: 1, width: '50%', height: 'auto', maxWidth: 600, minWidth: 200 }}
              />
              <img
                key={`stats-${index}`}
                src={image.stats}
                alt={`Athlete ${index} stats`}
                style={{ flex: 1, width: '50%', height: 'auto', maxWidth: 400, minWidth: 200, padding: 30 }}
              />
            </div>
          );
        })}
      </div>

      {/* Next Icon */}
      <img
        src={chevron}
        alt={`Next Athlete`}
        style={{
          width: '35px',
          height: 'auto',
          opacity: 1,
          transition: 'opacity 0.3s',
          cursor: 'pointer',
        }}
        onMouseEnter={(e) => (e.currentTarget.style.opacity = '0.7')}
        onMouseLeave={(e) => (e.currentTarget.style.opacity = '1')}
        onClick={nextImage}
      />
    </div>
  );
};

export default Athletes;
