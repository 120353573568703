import { Button as MantineButton } from '@mantine/core';

export default function Button({ color = 'gray', type, children, style, fullWidth = false, size = 'md', disabled = false, loading = false, shadow = false, onClick, onSubmit, ...props }: { color?: string, type?: "submit", children: React.ReactNode, style?: React.CSSProperties, fullWidth?: boolean, size?: string, disabled?: boolean, loading?: boolean, shadow?: boolean, onClick?: () => void, onSubmit?: () => void }) {
  return (
    <MantineButton
      variant="filled"
      type={type}
      color={color}
      fullWidth={fullWidth}
      size={size}
      disabled={disabled}
      loading={loading}
      radius={8}
      style={style}
      styles={{
        root: {
          boxShadow: shadow ? '0px 10px 20px rgba(0, 0, 0, 0.35)' : 'none',
        }
      }}
      className='button'
      onClick={onClick}
      onSubmit={onSubmit}
      {...props}
    >
      {children}
    </MantineButton>
  );
}
